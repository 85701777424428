
import { defineComponent, ref } from 'vue';

import type { UploadProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';
import { getVideoUrl } from '@/utils/mediaUrl';
const columns = [
  {
    title: 'File name',
    dataIndex: 'filename',
    key: 'name',
    isList: true,
    width: 200,
  },
  {
    title: 'Image',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
    width: 200,
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    TableList,
    MediaCards,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageFileList = ref<UploadProps['fileList']>([]);
    const mediaCardRef = ref();
    const tableListRef = ref();

    const text = ref('');
    const isLoading = ref(false);

    const onClick = async () => {
      const { image, scenario } = await mediaCardRef.value.submit();
      isLoading.value = true;
      axios
        .post(props.apiUrl, {
          faceId: image[0].faces[0].id,
          text: text.value,
          idPreset: scenario.value,
          name: image[0].uid,
        })
        .then((res) => {
          Modal.info({
            title: '요청 성공',
            content: '요청 성공했어요.',
          });
          tableListRef.value.getApiData();

          isLoading.value = false;
        })
        .catch((error) => {
          Modal.error({
            title: '요청 실패',
            content: '관리자에게 문의해보세요.',
          });
          isLoading.value = false;
        });

      tableListRef.value.getApiData();
    };

    // onMounted(() => {
    // });

    return {
      columns,
      // state
      mediaCardRef,
      tableListRef,
      imageFileList,
      text,
      isLoading,
      // func
      getVideoUrl,
      onClick,
    };
  },
});
