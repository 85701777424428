import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_MediaCards = _resolveComponent("MediaCards")!
  const _component_table_list = _resolveComponent("table-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MediaCards, {
      ref: "mediaCardRef",
      type: [
      { name: 'image', props: { search: true, min: 1 } },
      {
        name: 'scenario',
        props: {
          title: 'Free Set',
          description: '** Last selected free set is saved.',
          isLoading: _ctx.isLoading,
          scenarioList: [
            { label: 'none', value: 'none' },
            { label: 'low', value: 'low' },
            { label: 'middle', value: 'middle' },
            { label: 'high', value: 'high' },
          ],
        },
        event: {
          click: _ctx.onClick,
        },
      },
    ]
    }, {
      guide: _withCtx(() => [
        _createVNode(_component_a_typography_title, { level: 5 })
      ]),
      scenariooption: _withCtx(() => [
        _createVNode(_component_a_input, {
          value: _ctx.text,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
          placeholder: "Keyword"
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_table_list, {
      ref: "tableListRef",
      columns: _ctx.columns,
      "list-columns": [
      {
        title: 'Made by',
        key: 'username',
      },
    ],
      apis: { list: `image-results/virtual-skins?type=text` },
      type: "image",
      "table-handler-props": { mode: ['download'] }
    }, null, 8, ["columns"])
  ], 64))
}